/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Sagittarius = createSvgIcon(
    <>
        <defs />
        <path
            fill="#E83B75"
            d="M11.4 0v1.7H19l-9 9-3.3-3.2-1.2 1.2L8.8 12 0 20.8 1.2 22l8.8-8.8 3.3 3.3 1.2-1.2-3.3-3.3 9-9v8H22V0H11.4z"
        />
    </>,
    'Sagittarius'
)

export default Sagittarius
