/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Taurus = createSvgIcon(
    <>
        <defs />
        <path
            fill="#742069"
            d="M22 1.2L20.7 0A14.4 14.4 0 0111 4.2c-3 0-5.4-1.1-7-2A15 15 0 011.2 0L0 1.2a16.4 16.4 0 006.8 4 9 9 0 108.4 0 16.3 16.3 0 006.8-4zm-3.8 12a7.2 7.2 0 01-14.4 0c0-4 3.3-7.3 7.2-7.3 4 0 7.2 3.3 7.2 7.2z"
        />
    </>,
    'Taurus'
)

export default Taurus
