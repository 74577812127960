/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Scorpio = createSvgIcon(
    <>
        <defs />
        <path
            fill="#75226A"
            d="M20.3 16v3.4c-.5-.3-1-.6-1.3-1a9.4 9.4 0 01-1.7-4c-.8-3.3-.8-7.7-.8-10.8A3.5 3.5 0 0012.9 0c-1 0-2 .5-2.7 1.3A3.6 3.6 0 005 1a2.8 2.8 0 00-5 1.8v1.8h1.7V2.8a1.1 1.1 0 012.3 0V17h1.7V3.6a1.8 1.8 0 013.7 0V17H11V3.6a1.9 1.9 0 011.8-1.9 1.8 1.8 0 011.9 1.9c0 3.2 0 7.7.8 11.3.5 2 1.2 3.5 2.1 4.6l.9.8H16V22h6v-6h-1.7z"
        />
    </>,
    'Scorpio'
)

export default Scorpio
