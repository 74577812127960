/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Cancer = createSvgIcon(
    <>
        <defs />
        <path
            fill="#50B694"
            d="M3.6 3.3a15 15 0 016.6-1.6c1.9 0 3.7.5 5.5 1.4a5.3 5.3 0 00-2.8 9c1 1 2.3 1.5 3.8 1.5a5.3 5.3 0 003.8-9s-1-1.2-2.8-2.3A14 14 0 0010.2 0 16.7 16.7 0 000 3.5l1.1 1.3s1-.8 2.5-1.5zm13 8.6a3.6 3.6 0 110-7.2c2 0 3.7 1.6 3.7 3.6S18.7 12 16.7 12zm4.3 5.3s-1 .8-2.5 1.5a15 15 0 01-6.6 1.6c-1.9 0-3.7-.5-5.5-1.4a5.3 5.3 0 002.8-9C8 9 6.8 8.4 5.3 8.4a5.3 5.3 0 00-3.8 9s1 1.1 2.7 2.3a14 14 0 007.6 2.3A16.7 16.7 0 0022 18.5l-1.1-1.3zM5.3 10a3.6 3.6 0 110 7.2 3.6 3.6 0 110-7.2z"
        />
    </>,
    'Cancer'
)

export default Cancer
