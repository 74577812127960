/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
// @TODO: revisit once attributes are migrated
// @TODO: include variants
// @TODO: include forms
import React, { forwardRef, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInarticleMountedDispatch } from '@hmn/rtl-web-core/dispatchers/ads'
import { useHtmlContent } from '@hmn/rtl-web-core/hooks'

import { elements } from './HtmlContent.elements'
import styles from './HtmlContent.style'

const HtmlContentStyled = styled.article(props => ({ ...styles(props) }))
const HtmlContent = forwardRef(
    ({ className, id, article, PageComponent, endDecoration, data, page, insertElements, isMainContent }, ref) => {
        const htmlCont = useHtmlContent({
            docbook: data,
            customElements: elements,
            currentPage: page,
            insertElements
        })
        const dispatchInarticleMounted = useInarticleMountedDispatch()
        useEffect(() => {
            if (htmlCont?.Content && isMainContent) {
                // console.log('HtmlContent: mounted')
                dispatchInarticleMounted()
            }
        }, [htmlCont, isMainContent])

        return (
            <HtmlContentStyled endDecoration={endDecoration} className={className} id={id} ref={ref}>
                {PageComponent ? (
                    <PageComponent
                        Content={htmlCont.Content}
                        article={article}
                        currentPage={htmlCont.currentPage}
                        totalPages={htmlCont.totalPages}
                        blockList={htmlCont.blockList}
                    />
                ) : (
                    <htmlCont.Content />
                )}
            </HtmlContentStyled>
        )
    }
)

HtmlContent.propTypes = {
    PageComponent: PropTypes.elementType,
    article: PropTypes.shape({}),
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    page: PropTypes.number,
    insertElements: PropTypes.arrayOf(
        PropTypes.shape({
            every: PropTypes.number,
            afterBlocks: PropTypes.arrayOf(PropTypes.string), // array of block names after which to insert element
            offset: PropTypes.number,
            count: PropTypes.number,
            // eslint-disable-next-line react/forbid-prop-types
            elements: PropTypes.arrayOf(PropTypes.object)
        })
    ),
    endDecoration: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    isMainContent: PropTypes.bool
}

HtmlContent.defaultProps = {
    PageComponent: undefined,
    article: undefined,
    data: undefined,
    page: undefined,
    insertElements: undefined,
    endDecoration: false,
    className: undefined,
    id: undefined,
    isMainContent: false
}

export default withErrorBoundary(HtmlContent, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[HtmlContent]: ', error, componentStack)
    }
})
