import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { processAuthors } from '../../helpers/utils/processAuthors'
// import { useList } from '@hmn/rtl-web-core/hooks'
import { ArticleTags } from '../Article/components'
import { SocialShare } from '../Social/components'
import { Item, metaItemIconVariants } from './components/Item'
import styles from './Meta.net.style'

dayjs.extend(utc)
dayjs.extend(timezone)

const MetaStyled = styled.div(props => ({ ...styles(props) }))

function renderAuthors(authors) {
    return authors.map((author, index) => (
        <span key={author.slug || index}>
            <a href={`/autori/${author.slug}`} title={author.title}>
                {author.title}
            </a>
            {index < authors.length - 1 && <span className="metaItem_separator"> / </span>}
        </span>
    ))
}

function Meta({ className, authors = [], date, photoAuthor, tags, ...rest }) {
    const articleDate = useMemo(() => `${dayjs(date).utc().tz('Europe/Zagreb').format('D.M.YYYY.')}`, [date])
    const articleTime = useMemo(() => `${dayjs(date).utc().tz('Europe/Zagreb').format('H:mm')}`, [date])

    const authorElements = renderAuthors(processAuthors(authors))

    return (
        <MetaStyled
            className={className}
            isPhotoAuthorNewLine={photoAuthor?.length > 10}
            id="meta_info_wrapper"
            {...rest}>
            <div className="meta_info" id="meta_info">
                <div className="meta_info_items" id="meta_info_items">
                    {articleDate && <Item id="meta_date" title={articleDate} variant={metaItemIconVariants.ALPHA} />}
                    {articleTime && (
                        <Item id="meta_time" title={`${articleTime}`} variant={metaItemIconVariants.BETA} />
                    )}
                    {authorElements && (
                        <Item id="meta_author" title={authorElements} variant={metaItemIconVariants.GAMMA} />
                    )}
                    {photoAuthor && (
                        <Item
                            className="meta_info_item_photo"
                            id="meta_info_item_photo"
                            title={photoAuthor}
                            variant={metaItemIconVariants.DELTA}
                        />
                    )}
                </div>
                <SocialShare />
            </div>
            {tags && <ArticleTags id="meta_tags" className="meta_tags" tags={tags} />}
        </MetaStyled>
    )
}

Meta.propTypes = {
    className: PropTypes.string,
    authors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired
            })
        )
    ]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    photoAuthor: PropTypes.string,
    tags: PropTypes.oneOfType([() => null, PropTypes.object])
}

Meta.defaultProps = {
    className: undefined,
    authors: [],
    date: undefined,
    photoAuthor: undefined,
    tags: []
}

export default withErrorBoundary(Meta, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Meta]: ', error, componentStack)
    }
})
