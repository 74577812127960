/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Aquarius = createSvgIcon(
    <>
        <defs />
        <path
            fill="#742069"
            d="M15.7 12.1L12 8.7 8.3 12 4.6 8.7l-3.3 3.1L0 10.5 4.6 6l3.7 3.5L12 6l3.7 3.5L19.4 6l4.6 4.4-1.3 1.3-3.3-3.1-3.7 3.4zm3.7-9.5l3.3 3.1L24 4.4 19.4 0l-3.7 3.5L12 0 8.3 3.5 4.6 0 0 4.4l1.3 1.3 3.3-3.1L8.3 6 12 2.6 15.7 6l3.7-3.4z"
        />
    </>,
    'Aquarius'
)

export default Aquarius
