/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Search = createSvgIcon(
    <>
        <path d="M 17.9392 3.55556 C 12.13748 3.55556 7.4342 8.25883 7.4342 14.06059 C 7.4342 19.8624 12.13748 24.56569 17.9392 24.56569 C 23.74098 24.56569 28.44427 19.8624 28.44427 14.06059 C 28.44427 8.25883 23.74098 3.55556 17.9392 3.55556 Z M 3.87865 14.06059 C 3.87865 6.29515 10.1738 0 17.9392 0 C 25.70471 0 31.99982 6.29515 31.99982 14.06059 C 31.99982 21.82613 25.70471 28.12124 17.9392 28.12124 C 10.1738 28.12124 3.87865 21.82613 3.87865 14.06059 Z" />{' '}
        <path d="M 9.4995 21.20729 C 10.19376 21.90151 10.19376 23.0272 9.4995 23.72142 L 3.03486 30.18613 C 2.34059 30.88036 1.21496 30.88036 0.5207 30.18613 C -0.17357 29.49173 -0.17357 28.36622 0.5207 27.67182 L 6.98533 21.20729 C 7.67961 20.51307 8.80523 20.51307 9.4995 21.20729 Z" />
    </>,
    'Search'
)

export default Search
