import { toRem } from '../../../../helpers/theme'

const style = ({ theme, isDanas }) => ({
    display: 'inline-flex',
    '& a, button': {
        display: 'flex',
        flexDirection: 'column',
        transitionDuration: theme.transitions.default.duration,
        transitionTimingFunction: theme.transitions.default.timingFunction,
        transitionDelay: theme.transitions.default.delay,
        transitionProperty: 'opacity',
        '&:hover': {
            opacity: 0.8
        }
    },
    svg: {
        // @NOTE: client requested this dimensions on mobile and desktop
        width: toRem(32),
        height: toRem(32),
        ...(isDanas && {
            '& path.background': { fill: theme.colors.danas }
        })
        // // '@media (max-width:500px)': {
        // //     width: toRem(25),
        // //     height: toRem(25)
        // // },
        // '@media (max-width:400px)': {
        //     width: toRem(22),
        //     height: toRem(22)
        // },
        // '@media (max-width:370px)': {
        //     width: toRem(20),
        //     height: toRem(20)
        // }
    }
})

export default style
