/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Rss = createSvgIcon(
    // viewBox="0 0 15 15"
    <path
        d="M11.2292 0.0078125H3.75892C1.68914 0.0078125 0 1.69695 0 3.76673V11.2489C0 13.3068 1.68914 14.9959 3.75892 14.9959H11.2411C13.3109 14.9959 15 13.3068 15 11.237V3.76673C14.9881 1.69695 13.299 0.0078125 11.2292 0.0078125ZM4.0682 12.1648C3.37827 12.1648 2.81919 11.6057 2.81919 10.9277C2.81919 10.2378 3.37827 9.6906 4.0682 9.6906C4.75813 9.6906 5.31721 10.2497 5.31721 10.9277C5.31721 11.6057 4.75813 12.1648 4.0682 12.1648ZM7.19667 12.141C7.19667 10.9515 6.75654 9.88093 5.93577 9.06015C5.10309 8.22748 4.04441 7.79925 2.85488 7.79925V6.00305C6.18557 6.00305 8.99286 8.81035 8.99286 12.141H7.19667ZM10.3727 12.141C10.3727 7.97768 7.01824 4.6232 2.85488 4.6232V2.827C7.96987 2.827 12.1689 7.02605 12.1689 12.141H10.3727Z"
        fill="white"
    />,
    'Rss'
)

export default Rss
