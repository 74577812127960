/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const DanasHrLogo = createSvgIcon(
    <>
        <g clipPath="url(#clip0_197_739)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-0.000274658 0.743975H72.4208V17.2573H-0.000274658V0.743975Z"
                fill="#BC202F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.4205 0.743975H99.997V17.2573H72.4205V0.743975Z"
                fill="white"
            />
            <path
                d="M12.8573 9.0619C12.8573 8.53339 12.768 8.04518 12.5898 7.59702C12.411 7.14886 12.1637 6.76355 11.8473 6.44214C11.5303 6.12021 11.1478 5.87041 10.6988 5.69194C10.2499 5.51426 9.75469 5.42516 9.21381 5.42516H7.15897V12.6992H9.21381C9.75469 12.6992 10.2499 12.613 10.6988 12.4404C11.1478 12.2682 11.5303 12.0208 11.8473 11.6989C12.1637 11.3775 12.411 10.9985 12.5898 10.5613C12.768 10.1253 12.8573 9.63656 12.8573 9.09664V9.0619ZM15.1711 11.4409C14.8603 12.176 14.4201 12.8137 13.8503 13.3542C13.2805 13.8944 12.6009 14.3192 11.8125 14.6295C11.024 14.9397 10.1577 15.0948 9.21381 15.0948H4.49976V3.02895H9.21381C10.1577 3.02895 11.024 3.18117 11.8125 3.48613C12.6009 3.79056 13.2805 4.21274 13.8503 4.75265C14.4201 5.2931 14.8603 5.92795 15.1711 6.65774C15.482 7.38753 15.6374 8.17725 15.6374 9.02743V9.0619C15.6374 9.91235 15.482 10.7052 15.1711 11.4409Z"
                fill="white"
            />
            <path
                d="M23.3382 6.13186L21.7323 10.0444H24.9441L23.3382 6.13186ZM26.9989 15.0951L25.8938 12.3889H20.7826L19.6774 15.0951H16.9664L22.1467 2.94301H24.5987L29.779 15.0951H26.9989Z"
                fill="white"
            />
            <path
                d="M40.1223 15.0948L34.2686 7.42439V15.0948H31.6439V3.02895H34.0959L39.7597 10.4584V3.02895H42.3843V15.0948H40.1223Z"
                fill="white"
            />
            <path
                d="M50.621 6.13186L49.0151 10.0444H52.2271L50.621 6.13186ZM54.2817 15.0951L53.1766 12.3889H48.0654L46.9605 15.0951H44.2495L49.4298 2.94301H51.8815L57.0618 15.0951H54.2817Z"
                fill="white"
            />
            <path
                d="M67.3387 13.1214C67.1214 13.5871 66.8183 13.9777 66.4293 14.2936C66.0404 14.6097 65.5763 14.851 65.0373 15.0172C64.498 15.1838 63.9003 15.2676 63.2441 15.2676C62.3228 15.2676 61.4196 15.1093 60.5331 14.7937C59.6466 14.4773 58.8409 13.9918 58.1157 13.3368L59.687 11.4585C60.2396 11.9061 60.8065 12.2628 61.388 12.5269C61.969 12.7916 62.6052 12.9231 63.2959 12.9231C63.8485 12.9231 64.2773 12.8226 64.5822 12.6213C64.8872 12.4206 65.04 12.1421 65.04 11.7857V11.751C65.04 11.5791 65.0086 11.4264 64.9446 11.2941C64.8811 11.1623 64.76 11.0387 64.5809 10.9239C64.4021 10.8088 64.154 10.6942 63.8365 10.5789C63.5191 10.464 63.1065 10.3434 62.5986 10.2171C61.9868 10.0676 61.4334 9.90077 60.9364 9.717C60.4399 9.53376 60.0188 9.30623 59.6732 9.03654C59.3263 8.76631 59.0579 8.43059 58.8675 8.02778C58.6773 7.62549 58.5819 7.12615 58.5819 6.52816V6.49368C58.5819 5.9421 58.6847 5.44541 58.8906 5.00282C59.0965 4.56049 59.3855 4.17809 59.7569 3.85642C60.1285 3.53529 60.5724 3.2876 61.0886 3.11576C61.6045 2.94286 62.1735 2.85668 62.7949 2.85668C63.6814 2.85668 64.4962 2.98927 65.2387 3.2534C65.9812 3.51752 66.6631 3.897 67.2848 4.39104L65.9033 6.39026C65.3625 6.02245 64.8328 5.7326 64.3147 5.51993C63.7967 5.30725 63.2787 5.20091 62.7606 5.20091C62.2426 5.20091 61.854 5.30168 61.5952 5.50296C61.3359 5.7037 61.2066 5.95377 61.2066 6.25263V6.28737C61.2066 6.48255 61.2443 6.652 61.3192 6.79573C61.3941 6.93946 61.5296 7.0686 61.7259 7.18316C61.922 7.29852 62.1905 7.40751 62.5309 7.51067C62.872 7.61409 63.3015 7.73501 63.8211 7.87291C64.4329 8.03361 64.9778 8.215 65.457 8.41574C65.936 8.61728 66.3398 8.85833 66.669 9.13943C66.9981 9.42159 67.246 9.75148 67.4133 10.131C67.5807 10.5099 67.6649 10.9639 67.6649 11.4924V11.5269C67.6649 12.1246 67.5557 12.656 67.3387 13.1214Z"
                fill="white"
            />
            <path d="M74.5406 12.4054H77.2825V15.1086H74.5406V12.4054Z" fill="#080808" />
            <path
                d="M79.4845 2.77489H82.0573V7.33686C82.6495 6.57631 83.4111 5.88365 84.7144 5.88365C86.6611 5.88365 87.795 7.16767 87.795 9.24592V15.1089H85.2224V10.0569C85.2224 8.84072 84.6469 8.21542 83.6651 8.21542C82.6835 8.21542 82.0573 8.84072 82.0573 10.0569V15.1089H79.4845V2.77489Z"
                fill="#080808"
            />
            <path
                d="M90.0283 6.05259H92.6012V7.87733C93.1259 6.62725 93.9722 5.81605 95.4955 5.88367V8.56999H95.36C93.6508 8.56999 92.6012 9.60076 92.6012 11.7636V15.1086H90.0283V6.05259Z"
                fill="#080808"
            />
        </g>
        <defs>
            <clipPath id="clip0_197_739">
                <rect width="100" height="16.5138" fill="white" transform="translate(0 0.743164)" />
            </clipPath>
        </defs>
    </>,
    'DanasHrLogo'
)

export default DanasHrLogo
