/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Aries = createSvgIcon(
    <>
        <defs />
        <path
            fill="#50B694"
            d="M16 0a6 6 0 00-5 2.9 6 6 0 10-5 9V10A4.2 4.2 0 1110 6V22H12V6A4.2 4.2 0 1116 10V12A6 6 0 0016 0z"
        />
    </>,
    'Aries'
)

export default Aries
