/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const PhotoNet = createSvgIcon(
    <>
        <path
            fill="#666766"
            fillRule="evenodd"
            d="M4.5 2.2H2a25 25 0 00-.8 0 5.4 5.4 0 000 .8v6a25 25 0 000 .8 5.4 5.4 0 00.8 0h9a24.9 24.9 0 00.8 0 5.2 5.2 0 000-.8V3a25 25 0 000-.8 5.4 5.4 0 00-.8 0H8.5l-1-1h-2l-1 1zM5 0h3l1 1h2c1 0 1.4 0 1.7.3.3.3.3.8.3 1.7v6c0 1 0 1.4-.3 1.7-.3.3-.8.3-1.7.3H2c-1 0-1.4 0-1.7-.3C0 10.4 0 10 0 9V3c0-1 0-1.4.3-1.7C.6 1 1 1 2 1h2l1-1z"
            clipRule="evenodd"
        />
        <path
            fill="#666766"
            fillRule="evenodd"
            d="M6.5 7.3a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6zm0 1.2a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
            clipRule="evenodd"
        />
    </>,
    'PhotoNet'
)

export default PhotoNet
