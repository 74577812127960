/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const ClockNet = createSvgIcon(
    <>
        <path
            fill="#666766"
            fillRule="evenodd"
            d="M5.5 9.8a4.3 4.3 0 100-8.6 4.3 4.3 0 000 8.6zm0 1.2a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
            clipRule="evenodd"
        />
        <path fill="#666766" fillRule="evenodd" d="M4.9 3h1.2v2.2l1.8 1.3-.8 1L5 5.8V3z" clipRule="evenodd" />
    </>,
    'ClockNet'
)

export default ClockNet
