import { createSvgIcon } from '../../utils'

const ArrowRight = createSvgIcon(
    <path
        d="M22.2 14l-6.4-6.4 2.7-2.8L29.7 16 18.5 27.2l-2.7-2.8 6.4-6.4h-20v-4z"
        clipRule="evenodd"
        fill="#000"
        fillRule="evenodd"
    />,
    'ArrowRight'
)

export default ArrowRight
