/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Libra = createSvgIcon(
    <>
        <defs />
        <path
            fill="#50B694"
            d="M0 20.3h22V22H0v-1.7zM22 16v1.7H0v-1.7h5.8a8.9 8.9 0 1110.4 0H22zm-11 0a7.2 7.2 0 100-14.4 7.2 7.2 0 000 14.4z"
        />
    </>,
    'Libra'
)

export default Libra
