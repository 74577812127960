/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const AuthorNet = createSvgIcon(
    <path
        fill="#666766"
        fillRule="evenodd"
        d="M9 1.2H0V0h9v1.2zm0 2H0V2h9v1.2zm0 2H0V4h9v1.2zm0 2H0V6h9v1.2zm-3 2H0V8h6v1.2z"
        clipRule="evenodd"
    />,
    'AuthorNet'
)

export default AuthorNet
