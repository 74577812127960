/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Mail = createSvgIcon(
    <>
        <path d="M 7.51874 3.04762 C 7.55206 3.04762 7.58551 3.04763 7.61905 3.04763 L 24.48122 3.04762 C 25.83208 3.04755 26.99596 3.04748 27.92808 3.17279 C 28.92693 3.30709 29.8845 3.60994 30.66103 4.38657 C 31.43771 5.1632 31.7405 6.12069 31.8749 7.11956 C 32.00015 8.05163 32.00015 9.21554 32 10.5664 V 18.38598 C 32.00015 22.78446 32.00015 23.94834 31.8749 24.88046 C 31.7405 25.87931 31.43771 26.83688 30.66103 27.61341 C 29.8845 28.3901 28.92693 28.69288 27.92808 28.82728 C 26.99596 28.95253 25.83208 28.95253 24.48122 28.95238 H 7.51878 C 6.16792 28.95253 5.00401 28.95253 4.07194 28.82728 C 3.07307 28.69288 2.11558 28.3901 1.33895 27.61341 C 0.56232 26.83688 0.25947 25.87931 0.12517 24.88046 C -0.00014 23.94834 -0.00007 22.78446 0 21.4336 L 0.00001 10.66667 C 0.00001 10.63313 0.00001 10.59968 0 10.56635 C -0.00007 9.21553 -0.00014 8.05161 0.12517 7.11956 C 0.25947 6.12069 0.56232 5.1632 1.33895 4.38657 C 2.11558 3.60994 3.07307 3.30709 4.07194 3.17279 C 5.00399 3.04748 6.16791 3.04755 7.51874 3.04762 Z M 4.47803 6.19323 C 3.8171 6.2821 3.60994 6.42555 3.49394 6.54156 C 3.37794 6.65755 3.23448 6.86472 3.14562 7.52565 C 3.05086 8.23043 3.04762 9.18694 3.04762 10.66667 V 18.28571 C 3.04762 22.8131 3.05086 23.7696 3.14562 24.47436 C 3.23448 25.13524 3.37794 25.34248 3.49394 25.45844 C 3.60994 25.5744 3.8171 25.71794 4.47803 25.80678 C 5.18281 25.90156 6.13932 25.90476 7.61905 25.90476 H 24.38095 C 25.86072 25.90476 26.81722 25.90156 27.52198 25.80678 C 28.18286 25.71794 28.3901 25.5744 28.50606 25.45844 C 28.62202 25.34248 28.76556 25.13524 28.8544 24.47436 C 28.94918 23.7696 28.95238 22.8131 28.95238 21.33333 V 7.61905 C 28.95238 9.18694 28.94918 8.23043 28.8544 7.52565 C 28.76556 6.86472 28.62202 6.65755 28.50606 6.54156 C 28.3901 6.42555 28.18286 6.2821 27.52198 6.19323 C 26.81722 6.09848 25.86072 6.09524 24.38095 6.09524 H 7.61905 C 6.13932 6.09524 5.18281 6.09848 4.47803 6.19323 Z" />
        <path d="M 4.46562 9.16101 C 4.87639 8.68178 5.59787 8.62629 6.0771 9.03704 L 15.75208 17.32989 C 15.8947 17.45218 16.1053 17.45218 16.24792 17.32989 L 25.9229 9.03704 C 26.40213 8.62629 27.12366 8.68178 27.53432 9.16101 C 27.94514 9.64024 27.88968 10.36172 27.41044 10.7725 L 17.73547 19.0653 C 16.73676 19.92137 15.26324 19.92137 14.26456 19.0653 L 4.58958 10.7725 C 4.11035 10.36172 4.05486 9.64024 4.46562 9.16101 Z" />
    </>,
    'Mail'
)

export default Mail
