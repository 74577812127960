/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const MailHollowNet = createSvgIcon(
    <path
        d="M14.6673 2.00033C14.6673 1.26699 14.0673 0.666992 13.334 0.666992H2.66732C1.93398 0.666992 1.33398 1.26699 1.33398 2.00033M14.6673 2.00033V10.0003C14.6673 10.7337 14.0673 11.3337 13.334 11.3337H2.66732C1.93398 11.3337 1.33398 10.7337 1.33398 10.0003V2.00033M14.6673 2.00033L8.00065 6.66684L1.33398 2.00033"
        stroke="#080808"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
    />,
    'MailHollowNet'
)

export default MailHollowNet
