/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Video = createSvgIcon(
    <path
        d="M16 43.87V20.13c0-5.314 0-7.971 1.702-8.87 1.702-.9 3.897.597 8.287 3.59l17.41 11.87c3.512 2.395 5.267 3.592 5.267 5.28 0 1.688-1.755 2.885-5.266 5.28L25.989 49.15c-4.39 2.993-6.585 4.49-8.287 3.59C16 51.841 16 49.184 16 43.87z"
        fill="#fff"
    />,
    'Video'
)

export default Video
