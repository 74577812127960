/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'
// viewBox="0 0 10 11"
const ReadMore = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v6.586A2 2 0 0 1 12.414 11L10 13.414A2 2 0 0 1 8.586 14H4a2 2 0 0 1-2-2V3Zm9 0H4v9h5v-2h2V3Z"
            fill="#000"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 6H5V5h5v1Zm0 2H5V7h5v1Z" fill="#000" />
    </>,
    'ReadMore'
)

export default ReadMore
