/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Capricorn = createSvgIcon(
    <>
        <defs />
        <path
            fill="#75226A"
            d="M18.1 11.3c-1.4 0-2.8.3-4 .8v-7a5 5 0 00-8.7-3.5A2.8 2.8 0 000 2.8v1.8h1.7V2.8a1.1 1.1 0 012.3 0v12.5h1.7V5a3.4 3.4 0 016.7 0v8.1a9 9 0 00-1.3 1.3C9.5 16.4 8.7 19 8.7 22h1.7c0-2.5.7-5 2.3-6.8a5.8 5.8 0 005.4 3.8 3.9 3.9 0 000-7.7zm0 6a4 4 0 01-4-3.3c1.2-.6 2.5-1 4-1a2.2 2.2 0 010 4.3z"
        />
    </>,
    'Capricorn'
)

export default Capricorn
