/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const CalendarNet = createSvgIcon(
    <>
        <path
            fill="#666766"
            fillRule="evenodd"
            d="M9.8 9V3a24.9 24.9 0 000-.8 5.4 5.4 0 00-.8 0H2a25 25 0 00-.8 0 5.4 5.4 0 000 .8v6a25 25 0 000 .8 5.4 5.4 0 00.8 0h7a24.9 24.9 0 00.8 0 5.4 5.4 0 000-.8zM11 3v6c0 1 0 1.4-.3 1.7-.3.3-.8.3-1.7.3H2c-1 0-1.4 0-1.7-.3C0 10.4 0 10 0 9V3c0-1 0-1.4.3-1.7C.6 1 1 1 2 1h7c1 0 1.4 0 1.7.3.3.3.3.8.3 1.7z"
            clipRule="evenodd"
        />
        <path
            fill="#666766"
            fillRule="evenodd"
            d="M1.9 2V0h1.2v2H1.9zm2 0V0h1.2v2H3.9zm2 0V0h1.2v2H5.9zm2 0V0h1.2v2H7.9zM5 5H4V4h1v1zm2 0H6V4h1v1zm2 0H8V4h1v1zM3 7H2V6h1v1zm2 0H4V6h1v1zm2 0H6V6h1v1zm2 0H8V6h1v1zM3 9H2V8h1v1zm2 0H4V8h1v1zm2 0H6V8h1v1z"
            clipRule="evenodd"
        />
    </>,
    'CalendarNet'
)

export default CalendarNet
