/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const FacebookNet = createSvgIcon(
    <>
        <path
            fill="#1877F2"
            className="background"
            d="M0 11.2c0-5.2797 0-7.9196 1.6402-9.5598C3.2804 0 5.9202 0 11.2 0h5.6c5.2797 0 7.9196 0 9.5598 1.6402C28 3.2804 28 5.9202 28 11.2v5.6c0 5.2797 0 7.9196-1.6402 9.5598C24.7196 28 22.0797 28 16.8 28h-5.6c-5.2797 0-7.9196 0-9.5598-1.6402C0 24.7196 0 22.0797 0 16.8v-5.6z"
        />
        <path
            fill="#fff"
            d="M16.9476 14.932l.4407-2.8737h-2.7572v-1.8641c0-.7862.3852-1.5534 1.6202-1.5534h1.2536V6.1942S16.3672 6 15.2794 6c-2.2709 0-3.7551 1.3763-3.7551 3.868v2.1903H9v2.8737h2.5243v6.9472a10.0461 10.0461 0 003.1068 0V14.932h2.3165z"
        />
    </>,
    'FacebookNet'
)

export default FacebookNet
