/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const Close = createSvgIcon(
    <>
        <path d="M 28.035255,32.000001 0,3.9647831 3.9647831,0 32.000001,28.035255 Z" />
        <path d="M 3.9647831,32.000001 32.000001,3.9647831 28.035255,0 3.5648696e-6,28.035255 Z" />
    </>,
    'Close'
)

export default Close
