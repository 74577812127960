/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Pisces = createSvgIcon(
    <>
        <defs />
        <path
            fill="#3EBFE1"
            d="M19.4 18c-.8-1.4-1.8-3.5-2-6.1h3v-1.7h-3a15 15 0 014.2-9L21 .6l-.6-.6a16.4 16.4 0 00-4.7 10.2H6.3A16.4 16.4 0 001.6 0L.4 1.2a15 15 0 014.2 9h-3v1.7h3a14.4 14.4 0 01-4.2 8.8L1.6 22s1.2-1.1 2.4-3c1.4-2.3 2.2-4.7 2.4-7.1h9.2c.2 2.4 1 4.8 2.4 7 1.2 2 2.3 3 2.4 3.1l1.2-1.3s-1.1-1-2.2-2.7z"
        />
    </>,
    'Pisces'
)

export default Pisces
