import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant, hasImage, isHunk }) => ({
    width: '100%',
    ...(!isHunk && {
        marginBottom: '30px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '20px'
        }
    }),
    '.articleHead': {
        '&_title': {
            padding: '0 15px 0 15px',
            marginBottom: '11px',
            lineHeight: '1.8rem',
            [theme.breakpoints.up('md')]: {
                padding: 0,
                marginBottom: '35px',
                lineHeight: '3.2rem'
            }
        },
        '&_image': {
            marginBottom: '10px'
        },
        '&_imageProgressive': {
            ...(!hasImage && {
                paddingBottom: 0
            })
        },
        '&_inpicture_wrap': {
            position: 'relative'
        },

        '& .inpicture': {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            marginBottom: '10px',
            transform: 'translateX(-50%)'
        },

        '&_lead': {
            marginTop: '15px',
            marginBottom: '15px',
            padding: '0 15px 0 15px',
            [theme.breakpoints.up('md')]: {
                marginTop: '21px',
                marginBottom: '18px',
                padding: 0
            }
        },
        '&_lead > p': {
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: '-0.03em',
            fontSize: toRem(22),
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(30)
            }
        },
        '&_meta': {
            padding: '0 15px 0 15px',
            ...(variant === 'gamma' && { marginTop: '11px' }),
            [theme.breakpoints.up('md')]: {
                padding: 0,
                ...(variant === 'gamma' && { marginTop: '21px' })
            }
        }
    }
})

export default style
