/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const XHollowNet = createSvgIcon(
    <path
        d="M11.8131 0.720703H14.1131L9.08813 6.46445L15 14.2788H10.3713L6.74625 9.53883L2.5975 14.2788H0.29625L5.67125 8.13508L0 0.721328H4.74625L8.02312 5.05383L11.8131 0.720703ZM11.0063 12.9026H12.2806L4.05375 2.02508H2.68625L11.0063 12.9026Z"
        fill="#080808"
    />,
    'XHollowNet'
)

export default XHollowNet
