/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const ShareNet = createSvgIcon(
    <>
        <path
            fill="#F15B24"
            className="background"
            d="M0 11.2c0-5.28 0-7.92 1.64-9.56C3.28 0 5.92 0 11.2 0h5.6c5.28 0 7.92 0 9.56 1.64C28 3.28 28 5.92 28 11.2v5.6c0 5.28 0 7.92-1.64 9.56C24.72 28 22.08 28 16.8 28h-5.6c-5.28 0-7.92 0-9.56-1.64C0 24.72 0 22.08 0 16.8v-5.6z"
        />
        <path
            fill="#fff"
            d="M17.65 7c-.895 0-1.736.349-2.369.981l-2.608 2.61c.199-.029.398-.059.602-.059.562 0 1.112.11 1.619.312l1.625-1.625a1.602 1.602 0 012.263 2.263l-2.926 2.924c-.605.604-1.657.605-2.262 0l-1.237 1.239c.633.632 1.474.98 2.368.98.895 0 1.736-.349 2.369-.981l2.925-2.925A3.328 3.328 0 0021 10.35 3.354 3.354 0 0017.65 7z"
        />
        <path
            fill="#fff"
            d="M13.091 17.172l-1.61 1.61a1.602 1.602 0 01-2.263-2.263l2.926-2.925c.605-.604 1.657-.605 2.262 0l1.237-1.239c-1.265-1.264-3.471-1.264-4.737.001l-2.925 2.925A3.328 3.328 0 007 17.65 3.354 3.354 0 0010.35 21c.895 0 1.736-.349 2.369-.981l2.574-2.574c-.188.025-.375.055-.568.055-.57 0-1.123-.114-1.634-.328z"
        />
    </>,
    'ShareNet'
)

export default ShareNet
