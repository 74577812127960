/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'
// <svg width="90" height="22" viewBox="0 0 90 22" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
const Comment = createSvgIcon(
    <>
        <path
            className="colorme"
            d="M56.371 5.87773H57.3741V1.75404C57.3741 1.08533 57.9313 0.528076 58.6 0.528076C59.2687 0.528076 59.826 1.08533 59.826 1.75404V5.87773H62.8352C63.5039 5.87773 64.0611 6.43499 64.0611 7.1037C64.0611 7.7724 63.5039 8.32966 62.8352 8.32966H59.826V19.9763C59.826 20.645 59.2687 21.2023 58.6 21.2023C57.9313 21.2023 57.3741 20.645 57.3741 19.9763V8.32966H56.371C55.7023 8.32966 55.145 7.7724 55.145 7.1037C55.145 6.43499 55.7023 5.87773 56.371 5.87773Z"
            fill="#666766"
        />
        <path
            d="M20.8181 13.0106V19.9206C20.8181 20.5893 21.3754 21.1466 22.0441 21.1466C22.7128 21.1466 23.27 20.5893 23.27 19.9206V13.0106C23.27 10.1686 25.6105 7.82815 28.5082 7.82815C31.3503 7.82815 33.6907 10.1686 33.7465 13.0106V19.9206C33.7465 20.5893 34.3037 21.1466 34.9724 21.1466C35.6411 21.1466 36.1984 20.5893 36.1984 19.9206V13.0106C36.1984 8.83121 32.7434 5.37622 28.564 5.37622C24.2731 5.43195 20.8181 8.83121 20.8181 13.0106Z"
            fill="#666766"
        />
        <path
            d="M45.3373 21.1467H52.6373C53.306 21.1467 53.8633 20.5894 53.8633 19.9207C53.8633 19.252 53.306 18.6948 52.6373 18.6948H45.3373C42.7739 18.6948 40.6006 16.8001 40.2105 14.3482H47.4549C48.1236 14.3482 48.6808 13.7909 48.6808 13.1222C48.6808 12.4535 48.1236 11.8962 47.4549 11.8962H40.3777C41.0464 9.77866 42.9968 8.21835 45.3373 8.21835H52.3587C53.0274 8.21835 53.5847 7.66109 53.5847 6.99238C53.5847 6.32367 53.0274 5.76642 52.3587 5.76642H45.3373C41.1579 5.76642 37.7029 9.22141 37.7029 13.4008C37.7029 17.7474 41.1579 21.1467 45.3373 21.1467Z"
            fill="#666766"
        />
        <path
            d="M63.0582 18.416H64.117C64.5628 18.416 64.8972 18.7503 64.8972 19.1961V20.3664C64.8972 20.8122 64.5628 21.1465 64.117 21.1465H63.0582C62.6124 21.1465 62.2781 20.8122 62.2781 20.3664V19.1404C62.2781 18.7503 62.6124 18.416 63.0582 18.416Z"
            fill="#666766"
        />
        <path
            d="M88.5803 5.59906C84.9581 5.59906 82.0046 8.55252 82.0046 12.1747V14.1808V14.5152V15.4625V19.8091V20.7007C82.0046 20.9793 82.2275 21.2022 82.5062 21.2022C82.7848 21.2022 83.0077 20.9793 83.0077 20.7007V19.8091V15.4625V14.1251V12.1747V11.8403V11.5617V11.2831L83.0634 11.0044L83.1191 10.7258L83.1749 10.4472L83.2863 10.1686L83.3978 9.88993L83.5092 9.66703L83.6207 9.44412L83.7879 9.22122L83.955 8.99832L84.1222 8.77542L84.2894 8.55252L84.4566 8.32961L84.6237 8.16244L84.7909 7.99526L85.0138 7.82808L85.2367 7.66091L85.4596 7.49373L85.6825 7.32655L85.9054 7.2151L86.1283 7.10365L86.407 6.9922L86.6856 6.88075L86.9642 6.7693L87.2428 6.71357L87.5215 6.65785L87.8001 6.60212L88.0787 6.54639H88.3573H88.636C88.9146 6.54639 89.1375 6.32349 89.1375 6.04486C89.0261 5.76624 88.8032 5.59906 88.5803 5.59906Z"
            fill="#666766"
        />
        <path
            d="M67.5162 0.528076C67.7949 0.528076 67.962 0.750978 67.962 1.02961V8.77546C69.1323 6.88079 71.1941 5.5991 73.5903 5.5991C77.2125 5.5991 80.1659 8.55256 80.1659 12.1747V14.1808V14.2366V15.1839V19.8091V20.7007C80.1659 20.9794 79.943 21.2023 79.6644 21.2023C79.3858 21.2023 79.2186 20.9794 79.2186 20.7007V19.8091V15.2396V14.1251V12.1747C79.2186 11.6732 79.1629 11.2274 79.0514 10.7816L78.9957 10.503C78.7171 9.7228 78.327 9.05409 77.7697 8.38538C77.3796 7.99531 76.9896 7.66095 76.5438 7.38232C75.5407 6.82507 74.4819 6.54644 73.3674 6.54644C72.6987 6.60217 72.0857 6.71362 71.4727 6.99224L70.9712 7.21515C69.801 7.88385 68.9094 8.88691 68.3521 10.2243C68.1292 10.7816 68.0178 11.3946 68.0178 12.0075V15.1282V19.7534V20.4221V20.7007C68.0178 20.7565 68.0178 20.8122 68.0178 20.8679C67.962 20.9236 67.9063 20.9794 67.8506 21.0351C67.7949 21.0908 67.6834 21.0908 67.6277 21.0908C67.5162 21.0908 67.4605 21.0351 67.3491 20.9794C67.3491 20.9236 67.2933 20.8679 67.2933 20.8122V20.3664V19.8091V14.2366V12.119V0.973881C67.0147 0.750979 67.2376 0.528076 67.5162 0.528076Z"
            fill="#666766"
        />
        <path
            className="colorMe"
            d="M0 11.616C0 8.62921 0 7.1358 0.927886 6.20792C1.85577 5.28003 3.34918 5.28003 6.336 5.28003H9.504C12.4908 5.28003 13.9842 5.28003 14.9121 6.20792C15.84 7.1358 15.84 8.62921 15.84 11.616V14.784C15.84 17.7708 15.84 19.2643 14.9121 20.1921C13.9842 21.12 12.4908 21.12 9.504 21.12H6.336C3.34918 21.12 1.85577 21.12 0.927886 20.1921C0 19.2643 0 17.7708 0 14.784V11.616Z"
        />
        <path
            d="M5.32541 8.49332C5.7378 8.08092 6.40642 8.08092 6.81882 8.49332L10.7788 12.4533C11.1912 12.8657 11.1912 13.5343 10.7788 13.9467L6.81882 17.9067C6.40642 18.3191 5.7378 18.3191 5.32541 17.9067C4.91301 17.4943 4.91301 16.8257 5.32541 16.4133L8.5387 13.2L5.32541 9.98673C4.91301 9.57433 4.91301 8.90571 5.32541 8.49332Z"
            fill="white"
        />
    </>,
    'NetLogo'
)

export default Comment
