/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const Leo = createSvgIcon(
    <>
        <defs />
        <path
            fill="#3EBFE1"
            d="M20.3 18.5a1.8 1.8 0 01-1.8 1.8c-1 0-1.8-.9-1.8-1.9V4a3.9 3.9 0 00-7.8 0v8.9A5.3 5.3 0 000 16.7a5.3 5.3 0 0010.7 0V3.9a2.2 2.2 0 014.3 0v14.5a3.6 3.6 0 003.5 3.6 3.5 3.5 0 003.5-3.5h-1.7zm-15 1.8a3.6 3.6 0 110-7.2 3.6 3.6 0 010 7.2z"
        />
    </>,
    'Leo'
)

export default Leo
