import { responsive, toRem, toRems } from '../../../../helpers/theme'

// @Warning: /libs/net/ui/src/components/ExchangeWidget/ExchangeWidgetCard.style.js depends on this style function
// Make sure any markup changes are either made compatible with existing template or adjust template in UpScore dashboard for all relevant widgets
const style = ({
    theme,
    variant,
    sizeVariant,
    categoryColor,
    embedded,
    isArchive,
    imageWidth135,
    isSquareImg,
    isAmp
}) => {
    const card1x1 = sizeVariant === '1x1'
    const card1x2 = sizeVariant === '1x2'
    const card1x3 = sizeVariant === '1x3'
    const card2x3 = sizeVariant === '2x3'
    const card1x4 = sizeVariant === '1x4'
    const card1x5 = sizeVariant === '1x5'
    const card1x6 = sizeVariant === '1x6'

    const ampResponsive = properties => (isAmp ? properties[0] : responsive(properties))

    const sectionColor = categoryColor || theme.colors?.brand || '#F15B24'

    return {
        width: '100%',
        ...(variant === 'alpha' &&
            sizeVariant === '2x3' && {
                minWidth: toRem(330)
            }),
        position: 'relative',
        lineHeight: 1.2,
        '& .cardInner': {
            display: 'flex',
            flexDirection: 'column',
            color: theme.colors.text.default,
            border: 'none',
            ...(card1x1 &&
                variant === 'epsilon' && {
                    background: theme.colors.card.background.default,
                    ...(!isAmp && {
                        [theme.breakpoints.up('md')]: {
                            marginTop: toRem(50),
                            padding: toRems([0, 30])
                        }
                    })
                }),
            ...((variant === 'beta' || variant === 'delta') && {
                flexDirection: 'row',
                alignItems: 'center'
            }),
            ...(embedded && {
                background: theme.colors.card.background.default,
                '.end-article &': {
                    [theme.breakpoints.down('md')]: {
                        // background: 'transparent'
                    }
                },
                ...(!isAmp && {
                    [theme.breakpoints.up('md')]: {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr'
                    }
                })
            }),
            '&:hover': {
                border: 'none'
            }
        },
        '& .cardImage': {
            position: `relative`,
            ...(card1x1 &&
                !isAmp &&
                variant === 'epsilon' && {
                    [theme.breakpoints.up('md')]: {
                        marginTop: toRem(-50)
                    }
                }),
            ...(variant === 'beta' && {
                ...(card1x1 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: '305%' },
                        { width: '305%' },
                        { width: '305%' }
                    ])
                }),
                ...(card1x2 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: '170%' },
                        { width: '170%' },
                        { width: '170%' }
                    ])
                }),
                ...(card1x3 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(90), minWidth: toRem(90) },
                        { width: toRem(90), minWidth: toRem(90) },
                        { width: toRem(90), minWidth: toRem(90) }
                    ])
                }),
                ...(card2x3 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(255), minWidth: toRem(255) },
                        { width: toRem(255), minWidth: toRem(255) },
                        { width: toRem(255), minWidth: toRem(255) }
                    ])
                }),
                ...(card1x4 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(90), minWidth: toRem(90) },
                        { width: toRem(90), minWidth: toRem(90) },
                        { width: toRem(90), minWidth: toRem(90) }
                    ])
                }),
                ...(card1x5 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(255), minWidth: toRem(255) },
                        { width: toRem(255), minWidth: toRem(255) },
                        { width: toRem(255), minWidth: toRem(255) }
                    ])
                }),
                ...(card1x6 && {
                    ...ampResponsive([
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(110), minWidth: toRem(110) },
                        { width: toRem(imageWidth135 ? 135 : 110), minWidth: toRem(imageWidth135 ? 135 : 110) },
                        { width: toRem(imageWidth135 ? 135 : 110), minWidth: toRem(imageWidth135 ? 135 : 110) }
                    ])
                })
            }),
            ...(variant === 'delta' && {
                ...ampResponsive([
                    { width: toRem(110), minWidth: toRem(110) },
                    { width: toRem(110), minWidth: toRem(110) },
                    { width: toRem(110), minWidth: toRem(110) },
                    { width: toRem(imageWidth135 ? 135 : 110), minWidth: toRem(imageWidth135 ? 135 : 110) },
                    { width: toRem(imageWidth135 ? 135 : 110), minWidth: toRem(imageWidth135 ? 135 : 110) }
                ])
            }),
            ...(!isAmp && {
                [theme.breakpoints.up('md')]: {
                    ...(embedded && {
                        width: '100%' // !important'
                    })
                }
            }),
            img: {
                margin: 0,
                height: '100%',
                width: '100%',
                position: 'absolute',
                objectFit: 'cover'
            }
        },

        '& .cardContent_live': {
            position: 'absolute',
            right: '15px',
            bottom: '12px',
            zIndex: '10',
            display: 'block',
            width: '76px',
            height: '28px',
            background: '#DD0000',
            borderRadius: '100px',

            /* UŽIVO */
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '29px',
            textAlign: 'center',
            textTransform: 'uppercase',

            color: '#FFFFFF',

            '.dot': {
                display: 'inline-block',
                width: '6px',
                height: '6px',
                backgroundColor: '#FFFFFF',
                borderRadius: '100%',
                margin: '0 5px 2px -3px',

                animation: 'pulse 1.3s infinite',
                '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.1 },
                    '100%': { opacity: 1 }
                }
            }
        },

        '&.is_live.beta.is_size1x3 .cardContent_live, &.is_live.beta.is_size1x4 .cardContent_live': {
            right: '4px',
            bottom: '4px',
            width: '60px',
            height: '24px',
            fontSize: '11px',
            lineHeight: '25px',

            '.dot': {
                width: '5px',
                height: '5px',
                margin: '0 4px 1.5px -1px'
            }
        },

        '&.is_live.alpha.is_size1x4 .cardContent_live': {
            right: '8px',
            bottom: '6px'
        },

        '&.is_live.alpha.is_size1x6 .cardContent_live': {
            right: '6px',
            bottom: '6px'
        },

        '& .cardContent': {
            ...(isArchive && { display: 'flex', flexDirection: 'column' }),
            '&_titleWrapper': {
                zIndex: theme.zIndex.over,
                lineHeight: 1.2,
                ...(variant === 'alpha' && {
                    ...(card1x1 && {
                        ...ampResponsive([
                            { fontSize: toRem(24), padding: toRems([18, 0]) },
                            { fontSize: toRem(24), padding: toRems([18, 0]) },
                            { fontSize: toRem(42), padding: toRems([30, 0]) },
                            { fontSize: toRem(42), padding: toRems([30, 0]) },
                            { fontSize: toRem(42), padding: toRems([30, 0]) }
                        ])
                    }),
                    ...(card1x2 && {
                        ...ampResponsive([
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(32), padding: toRems([22, 0]) },
                            { fontSize: toRem(32), padding: toRems([22, 0]) },
                            { fontSize: toRem(32), padding: toRems([22, 0]) }
                        ])
                    }),
                    ...(card1x3 && {
                        ...ampResponsive([
                            { fontSize: toRem(20), padding: toRems([10, 0, 0]) },
                            { fontSize: toRem(20), padding: toRems([10, 0, 0]) },
                            { fontSize: toRem(isSquareImg ? 24 : 18), padding: toRems([12, 0, 0]) },
                            { fontSize: toRem(isSquareImg ? 24 : 18), padding: toRems([12, 0, 0]) },
                            { fontSize: toRem(isSquareImg ? 24 : 18), padding: toRems([12, 0, 0]) }
                        ])
                    }),
                    ...(card2x3 && {
                        ...ampResponsive([
                            { fontSize: toRem(20), padding: isArchive ? toRems([0, 0, 10, 0]) : toRems([10, 0, 0]) },
                            { fontSize: toRem(20), padding: isArchive ? toRems([0, 0, 10, 0]) : toRems([10, 0, 0]) },
                            { fontSize: toRem(36), padding: isArchive ? toRems([0, 0, 10, 0]) : toRems([10, 0, 0]) },
                            { fontSize: toRem(36), padding: isArchive ? toRems([0, 0, 22, 0]) : toRems([22, 0, 0]) },
                            { fontSize: toRem(36), padding: isArchive ? toRems([0, 0, 22, 0]) : toRems([22, 0, 0]) }
                        ])
                    }),
                    ...(card1x4 && {
                        ...ampResponsive([
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(18), padding: toRems([12, 0]) },
                            { fontSize: toRem(18), padding: toRems([12, 0]) },
                            { fontSize: toRem(18), padding: toRems([12, 0]) }
                        ])
                    }),
                    ...(card1x5 && {
                        ...ampResponsive([
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(20), padding: toRems([10, 0]) },
                            { fontSize: toRem(24), padding: toRems([16, 0]) },
                            { fontSize: toRem(24), padding: toRems([16, 0]) },
                            { fontSize: toRem(24), padding: toRems([16, 0]) }
                        ])
                    }),
                    ...(card1x6 && {
                        ...ampResponsive([
                            { fontSize: toRem(15), padding: toRems([10, 0]) },
                            { fontSize: toRem(15), padding: toRems([10, 0]) },
                            { fontSize: toRem(15), padding: toRems([10, 0]) },
                            { fontSize: toRem(15), padding: toRems([10, 0]) },
                            { fontSize: toRem(15), padding: toRems([10, 0]) }
                        ])
                    })
                }),
                ...(variant === 'beta' && {
                    ...(card1x1 && {
                        ...ampResponsive([
                            { fontSize: toRem(23), padding: toRems([0, 15]) },
                            { fontSize: toRem(23), padding: toRems([0, 15]) },
                            { fontSize: toRem(32), padding: toRems([0, 30]) },
                            { fontSize: toRem(32), padding: toRems([0, 30]) },
                            { fontSize: toRem(32), padding: toRems([0, 30]) }
                        ])
                    }),
                    ...(card2x3 && {
                        ...ampResponsive([
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(24), padding: toRems([0, 20]) },
                            { fontSize: toRem(24), padding: toRems([0, 20]) },
                            { fontSize: toRem(24), padding: toRems([0, 20]) }
                        ])
                    }),
                    ...(card1x2 && {
                        ...ampResponsive([
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(22), padding: toRems([0, 15]) },
                            { fontSize: toRem(22), padding: toRems([0, 15]) },
                            { fontSize: toRem(22), padding: toRems([0, 15]) }
                        ])
                    }),
                    ...((card1x3 || card1x4 || card1x5 || card1x6) && {
                        ...ampResponsive([
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) },
                            { fontSize: toRem(15), padding: toRems([0, 15]) }
                        ])
                    })
                }),
                ...(['gamma', 'epsilon'].includes(variant) && {
                    background: theme.colors.card.background.default,
                    ...((card1x1 || card1x2 || card2x3) && {
                        ...ampResponsive([
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(22), padding: toRems([20, 30]) },
                            { fontSize: toRem(22), padding: toRems([20, 30]) },
                            { fontSize: toRem(22), padding: toRems([20, 30]) }
                        ])
                    }),
                    ...((card1x3 || card1x4) && {
                        ...ampResponsive([
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(22), padding: toRem(20) },
                            { fontSize: toRem(22), padding: toRem(20) },
                            { fontSize: toRem(22), padding: toRem(20) }
                        ])
                    }),
                    ...((card1x5 || card1x6) && {
                        ...ampResponsive([
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) },
                            { fontSize: toRem(16), padding: toRem(20) }
                        ])
                    })
                }),
                ...(variant === 'delta' && {
                    ...ampResponsive([
                        { fontSize: toRem(15), padding: toRems([0, 15]) },
                        { fontSize: toRem(15), padding: toRems([0, 15]) },
                        { fontSize: toRem(22), padding: toRems([0, 15]) },
                        { fontSize: toRem(22), padding: toRems([0, 15]) },
                        { fontSize: toRem(22), padding: toRems([0, 15]) }
                    ])
                }),
                ...(variant === 'epsilon' && {
                    ...(card1x1 && {
                        ...ampResponsive([
                            { fontSize: toRem(24), padding: toRems([18, 15]) },
                            { fontSize: toRem(24), padding: toRems([18, 15]) },
                            { fontSize: toRem(42), textAlign: 'center', padding: toRems([30, 30, 35]) },
                            { fontSize: toRem(42), textAlign: 'center', padding: toRems([30, 30, 35]) },
                            { fontSize: toRem(42), textAlign: 'center', padding: toRems([30, 30, 35]) }
                        ])
                    })
                })
            }
        },
        '& .cardContent_subtitle': {
            marginBottom: toRem(4),
            color: sectionColor,
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: 900,
            textTransform: 'uppercase',
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color'
        },
        '& .cardContent_separator': {
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: 900,
            color: sectionColor,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color'
        },
        '& .cardContent_title': {
            color: theme.colors.card.title.default,
            fontWeight: theme.typography.fontWeight.bold,
            letterSpacing: '-0.03em',
            display: 'inline',
            fontSize: 'inherit'
        },
        '& .cardContent_lead': {
            marginTop: toRem(10),
            p: {
                fontSize: toRem(16),
                lineHeight: 1.4
            }
        },
        '& .cardContent_date': {
            color: '#b0b1b0',
            fontSize: toRem(18),
            ...(variant === 'alpha' && {
                ...(card2x3 && {
                    ...responsive([
                        { paddingTop: toRem(10) },
                        { paddingTop: toRem(10) },
                        { paddingTop: toRem(10) },
                        { paddingTop: toRem(22) },
                        { paddingTop: toRem(22) }
                    ])
                }),
                ...(card1x6 && {
                    ...responsive([
                        { fontSize: toRem(15), padding: toRems([10, 0]) },
                        { fontSize: toRem(15), padding: toRems([10, 0]) },
                        { fontSize: toRem(15), padding: toRems([10, 0]) },
                        { fontSize: toRem(15), padding: toRems([10, 0]) },
                        { fontSize: toRem(15), padding: toRems([10, 0]) }
                    ])
                })
            }),
            ...(variant === 'beta' && {
                ...(card2x3 && {
                    ...responsive([
                        { fontSize: toRem(15), padding: toRems([0, 15]) },
                        { fontSize: toRem(15), padding: toRems([0, 15]) },
                        { fontSize: toRem(16), padding: toRems([0, 20]) },
                        { fontSize: toRem(16), padding: toRems([0, 20]) },
                        { fontSize: toRem(16), padding: toRems([0, 20]) }
                    ])
                }),
                ...(card1x6 && {
                    ...responsive([
                        { fontSize: toRem(16), padding: toRems([0, 15]) },
                        { fontSize: toRem(16), padding: toRems([0, 15]) },
                        { fontSize: toRem(16), padding: toRems([0, 15]) },
                        { fontSize: toRem(16), padding: toRems([0, 15]) },
                        { fontSize: toRem(16), padding: toRems([0, 15]) }
                    ])
                })
            })
        },

        '&:hover': {
            '& .cardContent_label': {
                color: theme.colors.card.label.hover
            },
            '& .cardContent_separator': {
                color: theme.colors.card.label.hover
            },
            '& .cardContent_subtitle': {
                color: `${theme.colors.text.default}` // !important`
            }
        },
        a: {
            textDecoration: 'none'
        },

        '.is_section_naslovnica &': {
            '&.is_danas .cardContent_subtitle': {
                color: theme.colors.danas
            },
            '&.is_danas:hover .cardContent_subtitle': {
                color: theme.colors.card.label.hover
            },

            '&.is_danas .cardImage': {
                borderBottom: `1px solid ${theme.colors.danas}`
            },

            '&.is_danas .cardImage:before': {
                content: 'attr(data-before-content)',
                position: 'absolute',
                left: 0,
                bottom: 0,
                zIndex: 1,
                display: 'block',
                width: '76px',
                height: '19px',
                color: '#fff',
                backgroundColor: theme.colors.danas,
                fontSize: '18px',
                fontWeight: 700,
                letterSpacing: '0.1em',
                lineHeight: '19px',
                textAlign: 'center'
            },

            // '&.is_live.is_danas.is_size1x3 .cardImage:before': { display: 'none' },
            '&.is_live.is_danas.beta.is_size1x3 .cardImage:before': { display: 'none' },

            [theme.breakpoints.up('md')]: {
                '&.is_danas.is_size1x2 .cardImage:before': {
                    // width: '92px',
                    // height: '23px'
                    width: '72px',
                    height: '18px',
                    fontSize: '15px',
                    lineHeight: '18px'
                },

                '&.is_danas.is_size1x3 .cardImage:before': {
                    // width: '88px',
                    // height: '22.5px'
                    width: '64px',
                    height: '16px',
                    fontSize: '15px',
                    lineHeight: '16px'
                },

                '&.is_danas.beta.is_size1x3 .cardImage:before': {
                    // width: '52px',
                    // height: '12.5px'
                    width: '64px',
                    height: '16px',
                    fontSize: '15px',
                    lineHeight: '16px'
                },

                '&.is_danas.is_size1x4 .cardImage:before': {
                    width: '64px',
                    height: '16px',
                    fontSize: '15px',
                    lineHeight: '16px'
                },

                '&.is_danas.is_size1x6 .cardImage:before': {
                    width: '64px',
                    height: '16px',
                    fontSize: '15px',
                    lineHeight: '16px'
                }
            }
        },

        '& .contentMarker_wrapper': {
            display: 'flex',
            alignItems: 'center',
            position: `absolute`, // !important`,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },

        '&:not(.is_size1x3.alpha) .contentMarker_wrapper > *': {
            scale: '0.7'
        },

        '.is_section_danas &': {
            '.cardContent_subtitle': {
                color: theme.colors.danas
            }
        }
    }
}

export default style
