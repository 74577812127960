/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const House = createSvgIcon(
    <>
        <path
            fill="#080808"
            d="M14.2 14.8H9.7a.6.6 0 01-.6-.6v-2.8c0-.7-.5-1.2-1.2-1.2s-1.3.5-1.3 1.2v2.8c0 .3-.3.6-.6.6H1.6a.6.6 0 01-.6-.6V6.7c0-.5.3-1 .7-1.3L7.6 1h.7l5.9 4.3c.4.3.6.8.6 1.3v7.5c0 .3-.3.6-.6.6zm-3.9-1.1h3.4v-7l-.2-.4-5.6-4-5.5 4c-.2 0-.3.2-.3.4v7h3.4v-2.3c0-1.3 1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4v2.3z"
        />
        <path
            fill="#080808"
            fillRule="evenodd"
            d="M7.6 1.2L1.7 5.4c-.4.3-.6.8-.6 1.3v7.5c0 .3.2.5.5.5H6c.3 0 .5-.2.5-.5v-2.8c0-.7.6-1.3 1.4-1.3.7 0 1.3.6 1.3 1.3v2.8c0 .3.3.5.5.5h4.5c.3 0 .5-.2.5-.5V6.7c0-.5-.2-1-.6-1.3L8.2 1.2a.5.5 0 00-.6 0zm0-.2h.7l6 4.3c.4.3.6.8.6 1.4v7.6c0 .3-.3.6-.7.6H9.7a.7.7 0 01-.7-.7v-2.8c0-.6-.5-1.1-1.1-1.1-.7 0-1.2.5-1.2 1.1v2.8c0 .4-.3.7-.7.7H1.6a.7.7 0 01-.7-.7V6.7c0-.6.3-1.1.7-1.4l6-4.3zm.3 1.2l5.6 4c.2.1.3.3.3.5v7h-3.6v-2.3c0-1.2-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3v2.3H2v-7c0-.2.2-.4.3-.5l5.6-4zM2.4 6.4l-.2.3v6.8h3.2v-2c0-1.5 1.1-2.6 2.5-2.6s2.5 1.1 2.5 2.5v2.2h3.2v-7a.4.4 0 00-.2-.2l-5.5-4-5.5 4z"
            clipRule="evenodd"
        />
    </>,
    'House'
)

export default House
