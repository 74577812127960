/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const TwitterXNet = createSvgIcon(
    <>
        <g>
            <path
                fill="#000"
                className="background"
                d="M0 11.2c0-5.2797 0-7.9196 1.6402-9.5598C3.2804 0 5.9202 0 11.2 0h5.6c5.2797 0 7.9196 0 9.5598 1.6402C28 3.2804 28 5.9202 28 11.2v5.6c0 5.2797 0 7.9196-1.6402 9.5598C24.7196 28 22.0797 28 16.8 28h-5.6c-5.2797 0-7.9196 0-9.5598-1.6402C0 24.7196 0 22.0797 0 16.8v-5.6z"
            />
            <path
                fill="#fff"
                d="M 18.314 8 L 20.614 8 L 15.589 13.085 L 21.5 20.004 L 16.87 20.004 L 13.247 15.81 L 9.097 20.004 L 6.796 20.004 L 12.171 14.567 L 6.5 8 L 11.245 8 L 14.524 11.837 L 18.314 8 Z M 17.507 18.786 L 18.782 18.786 L 10.554 9.155 L 9.186 9.155 L 17.507 18.786 Z"
            />
        </g>
        ,
    </>,
    'TwitterXNet'
)

export default TwitterXNet
